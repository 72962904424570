import { YesObject } from '@yes.technology/react-toolkit'
import { createContext, useContext, useState } from 'react'

export type SelectAndLinkData = {
  uuidObjectclass: string
  callback: (values: YesObject[]) => void
}

export type SelectAndLinkFrameContextType = {
  selectAndLinkData: SelectAndLinkData | null
  setSelectAndLinkData: (data: SelectAndLinkData | null) => void
}

export const SelectAndLinkFrameContext =
  createContext<SelectAndLinkFrameContextType>({
    selectAndLinkData: null,
    setSelectAndLinkData: () => {}
  })

export const SelectAndLinkFrameContextProvider = ({
  children
}: React.PropsWithChildren) => {
  const [selectAndLinkData, setSelectAndLinkData] =
    useState<SelectAndLinkData | null>(null)

  return (
    <SelectAndLinkFrameContext.Provider
      value={{
        selectAndLinkData,
        setSelectAndLinkData
      }}
    >
      {children}
    </SelectAndLinkFrameContext.Provider>
  )
}

export const useSelectAndLinkFrameContext = () =>
  useContext(SelectAndLinkFrameContext)
