import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18n-lite'
import {
  DocumentListCard,
  Unifree,
  NavigationSlider,
  feedbackcard,
  ErrorPage503
} from '@yes.technology/react-toolkit'

import { GridContainer } from 'style/grid'
import * as interactionApi from '../utils/api'
import { interactionModelOperations } from '../redux'
import InteractionStep from '../components/InteractionStep'
import ScrollToTop from '../../shared/ScrollToTop'
import Error from '../../shared/Error'
import Success from '../components/Success'
import { getActivitiesFromInteractionModel } from '../../../utils/interactionModel'
import hasPendingDecisionModel from '../utils/hasPendingDecisionModel'
import {
  useValidationCallbacks,
  BlueprintsContext,
  useInteractionModel,
  useGeolocation,
  useInitialConfig,
  useStep,
  useSequenceRules,
  useNavigation
} from '../hooks'
import moment from 'moment'
import InteractionInformation from '../components/InteractionInformation'
import { persistor } from 'state/store'
import Loading from 'modules/shared/Loading'
import { StickyArea } from './InteractionContainer.styles'
import ReviewSaveButton from '../components/Review/components/ReviewSaveButton/ReviewSaveButton'
import { ReviewSaveButtonContextProvider } from '../components/Review/components/ReviewSaveButton/ReviewSaveButtonContext'
import { SelectAndLinkFrameContextProvider } from '../components/SelectAndLinkFrame/SelectAndLinkFrameContext'

const InteractionContainer = () => {
  const { t } = useTranslation()

  const [isFetchingBlueprints, setIsFetchingBlueprints] = useState(false)
  const [isFirstBlueprintsRequest, setIsFirstBlueprintsRequest] = useState(true)

  const dispatch = useDispatch()

  const apiState = useSelector((state) => state.api)

  const blueprintsPromise = useRef(null)
  const shouldExecuteInitialBlueprint = useRef(true)

  const {
    isFetchingInteractionModel,
    interactionModel,
    interactionModelError
  } = useInteractionModel({ currentTime: moment().format() })

  useValidationCallbacks(interactionModel.data)
  useGeolocation(interactionModel?.geolocation)
  useInitialConfig(interactionModel)

  const clearReduxPersist = () => {
    persistor.pause()
    persistor.flush().then(() => persistor.purge())
  }

  const {
    totalSteps,
    currentStep,
    currentStepData,
    changeStep,
    isLoadedSteps,
    isInteractionFinished
  } = useStep(interactionModel, clearReduxPersist)
  const showTextForTotal = hasPendingDecisionModel(interactionModel?.data)

  const { executeSequenceDecisionModel, applyInitialDecisionModel } =
    useSequenceRules()

  const { showDoubleArrowhead } = useNavigation(
    interactionModel?.data?.interaction_model_item_groups,
    totalSteps
  )

  const updateActivities = useCallback(
    (activities) => {
      dispatch(interactionModelOperations.updateActivities(activities))
    },
    [dispatch]
  )

  const executeBlueprints = useCallback(() => {
    const interactionModelUuid = interactionModel.data.uuid
    const activities = getActivitiesFromInteractionModel(interactionModel)
    const workflowUuid = interactionModel.workflowUuid

    setIsFetchingBlueprints(true)

    blueprintsPromise.current = interactionApi
      .executeBlueprints({ interactionModelUuid, workflowUuid, activities })
      .then((response) => {
        if (response.success) {
          blueprintsPromise.current.isResolved = true

          updateActivities(response.data?.activities)

          applyInitialDecisionModel(
            response.data?.activities,
            interactionModel?.data?.interaction_model_item_groups
          )
        }
      })
      .catch((error) => {
        if (isFirstBlueprintsRequest && error.status === 503) {
          dispatch({ type: 'api/ERROR', data: { status: error.status } })
          return
        }
        if (error.status === 503) {
          feedbackcard(t('system.unavailable'), {
            type: 'error',
            message: t('system.unavailable-des')
          })
        }
      })
      .finally(() => {
        setIsFetchingBlueprints(false)
        setIsFirstBlueprintsRequest(false)
      })
  }, [
    interactionModel,
    updateActivities,
    applyInitialDecisionModel,
    isFirstBlueprintsRequest,
    dispatch,
    t
  ])

  useEffect(() => {
    if (
      shouldExecuteInitialBlueprint.current &&
      !isFetchingInteractionModel &&
      interactionModel.data.uuid
    ) {
      shouldExecuteInitialBlueprint.current = false

      executeBlueprints()
    }
  }, [
    isFetchingInteractionModel,
    interactionModel.data.uuid,
    executeBlueprints
  ])

  const handleChangeActivity = useCallback(
    (activity) => {
      executeSequenceDecisionModel(
        activity,
        currentStepData?.activity_models,
        interactionModel?.data?.interaction_model_item_groups
      )

      if (blueprintsPromise.current && !blueprintsPromise.current.isResolved) {
        blueprintsPromise.current.then(() => updateActivities([activity]))

        return
      }

      updateActivities([activity])
    },
    [
      updateActivities,
      currentStepData?.activity_models,
      executeSequenceDecisionModel,
      interactionModel?.data?.interaction_model_item_groups
    ]
  )

  const shouldDisplayError =
    !isFetchingInteractionModel && interactionModelError

  const isSplashScreen =
    !shouldDisplayError && (isFetchingInteractionModel || !isLoadedSteps)

  if (isSplashScreen) {
    return <Loading active />
  }

  if (shouldDisplayError)
    return (
      <GridContainer>
        <Error errorMessage={interactionModelError} />
      </GridContainer>
    )

  if (apiState.isError && apiState.status === 503) {
    return (
      <GridContainer>
        <ErrorPage503 onTryAgain={() => window.location.reload()} />
      </GridContainer>
    )
  }

  return (
    <BlueprintsContext.Provider
      value={{ executeBlueprints, isFetchingBlueprints }}
    >
      <ScrollToTop />

      <InteractionInformation
        cards={interactionModel?.data?.information_cards}
        {...{ currentStep, isInteractionFinished }}
      />

      {!isInteractionFinished && (
        <>
          <GridContainer>
            <DocumentListCard className='my-3'>
              <Unifree
                informational
                label={t('interactionmodel')}
                value={interactionModel.data?.des}
                required
              />
            </DocumentListCard>
          </GridContainer>

          <ReviewSaveButtonContextProvider>
            <StickyArea>
              <NavigationSlider
                currentPosition={currentStep}
                first={1}
                total={totalSteps}
                hierarchyLevel='level_2'
                doubleArrowhead={showDoubleArrowhead}
                textForCurrent={t('navigation-slider.text-for-current')}
                textForTotal={
                  showTextForTotal && t('navigation-slider.text-for-total')
                }
                afterClick={changeStep}
                heightClass='xSmall'
              />
              <ReviewSaveButton {...{ totalSteps, currentStep, changeStep }} />
            </StickyArea>

            <GridContainer>
              <SelectAndLinkFrameContextProvider>
                <InteractionStep
                  totalSteps={totalSteps}
                  currentStep={currentStep}
                  interactionItemGroup={currentStepData}
                  handleChangeStep={changeStep}
                  handleChangeActivity={handleChangeActivity}
                  coord={interactionModel?.geolocation}
                />
              </SelectAndLinkFrameContextProvider>
            </GridContainer>
          </ReviewSaveButtonContextProvider>
        </>
      )}

      {isInteractionFinished && (
        <GridContainer>
          <Success />
        </GridContainer>
      )}
    </BlueprintsContext.Provider>
  )
}

export default InteractionContainer
