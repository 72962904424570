import { Icon, SyntheticButton } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import { Container } from './ActivityWithActions.styles'

const ALLOWED_ACTIONS = ['select_and_link'] as const

type AllowedAction = (typeof ALLOWED_ACTIONS)[number]

const ACTION_ICONS: Record<
  AllowedAction,
  React.ComponentProps<typeof Icon>['iconName']
> = {
  select_and_link: 'SelectAndRelateSign'
}

export type ActivityWithActionsProps = React.PropsWithChildren<{
  actions: string[]
  onClickAction: (action: string) => void
}>

export default function ActivityWithActions({
  actions,
  onClickAction,
  children
}: ActivityWithActionsProps) {
  const { t } = useTranslation()

  const filteredAllowedActions = actions.filter(
    (action): action is AllowedAction =>
      ALLOWED_ACTIONS.includes(action as AllowedAction)
  )

  if (!filteredAllowedActions.length) {
    return children
  }

  return (
    <Container>
      {children}

      <SyntheticButton
        options={filteredAllowedActions.map((action) => ({
          title: t(`activity-input.actions.labels.${action}`),
          children: <Icon iconName={ACTION_ICONS[action]} />,
          onClick: () => onClickAction(action)
        }))}
        dropdownLabel={t('activity-input.actions.more')}
      />
    </Container>
  )
}
